/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _get from 'lodash/get';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Container, {
  ContainerChildrenWrapper,
} from '../components/Container';
import {
  ContentWrap,
  HeroHeading,
} from '../components/Global';
import HelpArticleContext from '../components/Context/HelpArticleContext';
import ContentfulRichText from '../components/ContentfulRichText';
import HeaderSummary from '../components/HeaderSummary';
import TrackingYesNo from '../components/TrackingYesNo';
// Helpers
import { renderOptionsForEmbeddedArticles } from '../components/ContentfulRichText/helper';
// Styles
import GlobalStyles from '../styles/GlobalStyles';


const HelpArticleEmbeded = (props) => {
  const {
    data,
  } = props;
  const article = _get(data, 'contentfulHelpArticle', null);

  return (
    <HelpArticleContext.Provider value={article}>
      <GlobalStyles
        theme="light"
        isEmbededView
      />
      <Container>
        <ContainerChildrenWrapper>
          <ContentWrap mdMb="0">
            <HeroHeading mt="30px" mb="60px">{article.title}</HeroHeading>
            <HeaderSummary
              richText={article.body}
            />
            <ContentfulRichText
              richText={article.body}
              renderOptions={renderOptionsForEmbeddedArticles}
            />
            <div className="section">
              <TrackingYesNo
                title="Was this article helpful?"
                label={article.title}
              />
            </div>
          </ContentWrap>
        </ContainerChildrenWrapper>
      </Container>
    </HelpArticleContext.Provider>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
HelpArticleEmbeded.propTypes = {
  data: PropTypes.shape({
    contentfulHelpArticle: PropTypes.shape({
      body: PropTypes.shape({
        raw: PropTypes.string,
      }),
      excerpt: PropTypes.shape({
        excerpt: PropTypes.string,
      }),
      id: PropTypes.string,
      slug: PropTypes.string,
      subCategory: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          category: PropTypes.shape({
            id: PropTypes.string,
            categoryTitle: PropTypes.string,
          }),
        }),
      ),
      title: PropTypes.string,
      updatedAtISO: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default HelpArticleEmbeded;

export const pageQuery = graphql`
  query HelpArticleEmbededQuery($slug: String!) {
    contentfulHelpArticle(slug: {eq: $slug}) {
      slug
      id
      title
      excerpt {
        excerpt
      }
      updatedAtISO: updatedAt
      subCategory:category {
        id
        name
        category {
          id
          categoryTitle
          slug
        }
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData
          }
          ... on ContentfulElementTip {
            __typename
            contentful_id
            text {
              text
            }
          }
          ... on ContentfulElementStep {
            __typename
            contentful_id
            number
            content {
              raw
            }
          }
          ... on ContentfulElementVideo {
            __typename
            contentful_id
            url
          }
        }
      }
    }

  }
`;
