/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import HeaderSummaryWrapper from './index.style';
import ContentfulRichText from '../ContentfulRichText';
import { renderOptionsHeader } from '../ContentfulRichText/helper';


const HeaderSummary = (props) => {
  const {
    richText,
  } = props;

  if (!_get(richText, 'raw', '').includes('heading-2')) {
    return null;
  }

  return (
    <HeaderSummaryWrapper>
      <span className="title">In this article</span>
      <ContentfulRichText
        richText={richText}
        renderOptions={renderOptionsHeader}
      />
    </HeaderSummaryWrapper>
  );
};

HeaderSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  richText: PropTypes.any.isRequired,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default HeaderSummary;
