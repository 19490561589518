/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import TrackingYesNoWrapper from './index.style';

/*------------------------------------------------*/
/* COMPONENT
/*------------------------------------------------*/
const TrackingYesNo = (props) => {
  /* USE STATE -----------------------------------*/
  const [optionClicked, setOptionClicked] = React.useState(false);
  /* PROPS ---------------------------------------*/
  const {
    title,
    category,
    actionYes,
    actionNo,
    label,
    messageAfterClick,
  } = props;

  /* METHODS -------------------------------------*/
  const onClickYes = () => {
    trackCustomEvent({
      category,
      action: actionYes,
      label,
    });

    setOptionClicked(true);
  };

  const onClickNo = () => {
    trackCustomEvent({
      category,
      action: actionNo,
      label,
    });

    setOptionClicked(true);
  };

  /* RENDER --------------------------------------*/
  return (
    <TrackingYesNoWrapper>
      <span className="title">{title}</span>
      {!optionClicked && (
        <div className="button-wrapper">
          <button
            type="button"
            onClick={onClickYes}
          >
            Yes
          </button>
          <span>/</span>
          <button
            type="button"
            onClick={onClickNo}
          >
            No
          </button>
        </div>
      )}
      {optionClicked && (
        <div className="message">{messageAfterClick}</div>
      )}
    </TrackingYesNoWrapper>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
TrackingYesNo.defaultProps = {
  category: 'Help article feedback',
  actionYes: 'Helpful',
  actionNo: 'Not helpful',
  label: '',
  messageAfterClick: 'Thank you for your feedback',
};
TrackingYesNo.propTypes = {
  // Required props:
  title: PropTypes.string.isRequired,
  // Optional props:
  category: PropTypes.string,
  actionYes: PropTypes.string,
  actionNo: PropTypes.string,
  label: PropTypes.string,
  messageAfterClick: PropTypes.string,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TrackingYesNo;
