import styled from 'styled-components';
import { rem } from 'polished';
import theme from '../../styles/theme';

const HeaderSummaryWrapper = styled.div`
  background: ${theme.background.lightGray};
  border-radius: 4px;
  padding: 20px;
  border-left: 14px solid ${theme.textColor.blueText};
  margin: 0 0 60px 0;

  a {
    display: block;
    margin-bottom: 12px;
  }
  .title {
    display: block;
    font-size: ${rem(theme.font.paragraph.fontSize)};
    line-height: ${rem(theme.font.paragraph.lineHeight)};
    font-weight: 700;
    margin: 0 0 10px 0;
  }
`;
HeaderSummaryWrapper.displayName = 'HeaderSummaryWrapper';

export default HeaderSummaryWrapper;
