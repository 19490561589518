/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
import { rem } from 'polished';
import theme from '../../styles/theme';


const TrackingYesNoWrapper = styled.div`
  display: flex;

  .title,
  .message {
    font-size: ${rem(theme.font.paragraph.fontSize)};
    line-height: ${rem(theme.font.paragraph.lineHeight)};
    margin: 0 10px 0 0;
  }
  .button-wrapper {
    display: flex;

    button {
      background: none;
      border: none;
      cursor: pointer;
      outline: none;
      margin: 0;
      padding: 0 12px;
      font-size: ${rem(theme.font.paragraph.fontSize)};
      line-height: ${rem(theme.font.paragraph.lineHeight)};
      color: ${theme.textColor.blueText};

      &:hover {
        color: ${theme.hover.hoverBlue};
      }
    }
  }
`;
TrackingYesNoWrapper.displayName = 'TrackingYesNoWrapper';

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TrackingYesNoWrapper;
